import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Question from 'Components/contact/Question'
import { ClientType } from 'src/templates/clients'

interface IProps {
  faq: ClientType
}

const FAQ: React.FC<IProps> = ({ faq }) => (
  <section className="py-5">
    <h2 className="text-center py-5">{faq.header}</h2>
    <Container className="pb-5">
      <Row>
        <Col xs={0} md={1} lg={2} />
        <Col xs={12} md={10} lg={8}>
          <Question questionId={faq.q1.q} answerId={faq.q1.a} />
          <Question questionId={faq.q2.q} answerId={faq.q2.a} />
          <Question questionId={faq.q3.q} answerId={faq.q3.a} />
          <Question questionId={faq.q4.q} answerId={faq.q4.a} />
          {/* <Question questionId={faq.q5.q} answerId={faq.q5.a} /> */}
          <Question questionId={faq.q6.q} answerId={faq.q6.a} />
          <Question questionId={faq.q7.q} answerId={faq.q7.a} />
          <Question questionId={faq.q8.q} answerId={faq.q8.a} />
        </Col>
      </Row>
    </Container>
  </section>
)

export default FAQ
