import React from 'react'
import { Container } from 'reactstrap'
import image from 'images/contact/laptop-line-chart@2x.png'
import imageMobile from 'images/device-laptop-mockups@2x.png'

const ProductShowcase: React.FC = () => (
  <Container>
    <img className="w-100 d-none d-md-block" src={image} />
    <img className="w-100 d-block d-md-none" src={imageMobile} />
  </Container>
)

export default ProductShowcase
