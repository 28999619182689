import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Row } from 'reactstrap'
import FormattedHtmlMessage from 'Components/common/FormattedHtmlMessage'

interface IProps {
  headerId: string
  messageId: string
  noIntl: boolean
}

const Enticement: React.FC<IProps> = ({ noIntl, headerId, messageId }) => (
  <section>
    <Container className="text-center py-5">
      <Row>
        <div className="col-md-1 col-lg-2 d-none d-sm-block" />
        <div className="text-center py-7 px-3 col-12 col-md-10 col-lg-8">
          <h1 className="mb-4 font-weight-bold">
            {noIntl ? headerId : <FormattedMessage id={headerId} />}
          </h1>
          <p className="lead m-0">
            {noIntl ? messageId : <FormattedHtmlMessage id={messageId} />}
          </p>
        </div>
      </Row>
    </Container>
  </section>
)

export default Enticement
