import React, { Component } from 'react'
import BgImage from 'Components/common/BgImage'
import { graphql, StaticQuery } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import Parser from 'html-react-parser'

interface State {
  apiUrl: string
  messageFocused: boolean
  emailFocused: boolean
  message: string
  email: string
  buttonState: string
}

class Message extends Component<any, State> {
  private messageInput: React.RefObject<any>
  private emailInput: React.RefObject<any>

  constructor(props: any) {
    super(props)
    this.messageInput = React.createRef()
    this.emailInput = React.createRef()
    this.state = {
      apiUrl: process.env.GATSBY_BASE_URL || '',
      messageFocused: false,
      emailFocused: false,
      message: '',
      email: '',
      buttonState: props.message.buttonIdle,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  componentDidUpdate() {
    // tslint:disable-next-line
    this.state.messageFocused &&
      (this.messageInput && this.messageInput.current.focus())
    // tslint:disable-next-line
    this.state.emailFocused &&
      (this.emailInput && this.emailInput.current.focus())
  }

  handleClick = (e: any) => {
    switch (e.target.id) {
      case 'message-wrapper':
      case 'message':
        this.setState({ messageFocused: true, emailFocused: false })
        break
      case 'email-wrapper':
      case 'email':
        this.setState({ messageFocused: false, emailFocused: true })
        break
      default:
        this.setState({ messageFocused: false, emailFocused: false })
    }
  }

  currentCulture = () => {
    const pathnameArray: string[] = this.props.location.pathname.split('/')
    switch (pathnameArray[1]) {
      case 'kontakt':
        return 'nb-NO'
      case 'en':
        return 'en-US'
      case 'pl':
        return 'pl-PL'
      default:
        return 'en-US'
    }
  }

  render() {
    const buttonState: any = {
      IDLE: this.props.message.buttonIdle,
      SENDING: this.props.message.buttonSending,
      SUCCESS: this.props.message.buttonSuccess,
      ERROR: this.props.message.buttonError,
    }

    const { message } = this.props
    return (
      <StaticQuery
        query={graphql`
          query messageBackgroundImageQuery {
            bgImage: file(
              relativePath: { eq: "contact/contact-intro-bg@2x.png" }
            ) {
              childImageSharp {
                fixed(width: 2560, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <section>
            <BgImage imgFixed={data.bgImage.childImageSharp.fixed}>
              <div style={{ height: 500 }} className="container py-8">
                <h1 className="display-4 mb-4 text-center">
                  {Parser(message.header)}
                </h1>
              </div>
            </BgImage>
            <Container className="message-container">
              <Row>
                <Col xs={0} md={2} lg={3} />
                <Col xs={12} md={8} lg={6}>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      this.setState({ buttonState: buttonState.SENDING })
                      this.props.googleReCaptchaProps
                        .executeRecaptcha()
                        .then((token: string) => {
                          const model = {
                            reCaptcha: token,
                            message: this.state.message,
                            email: this.state.email,
                            culture: this.currentCulture(),
                          }

                          axios
                            .post(`${this.state.apiUrl}/api/contact`, model)
                            .then(() => {
                              this.setState({
                                buttonState: buttonState.SUCCESS,
                                message: '',
                                email: '',
                              })
                              this.messageInput.current.value = ''
                              this.emailInput.current.value = ''
                            })
                            .catch(() =>
                              this.setState({
                                buttonState: buttonState.ERROR,
                              })
                            )
                        })
                        .catch(() => {
                          this.setState({
                            buttonState: buttonState.ERROR,
                          })
                        })
                    }}
                  >
                    <FormGroup
                      id="message-wrapper"
                      className={`form-group-default ${this.state
                        .messageFocused && 'focused'}`}
                      style={{ height: 300 }}
                    >
                      <Label for="message">{message.messageLabel}</Label>
                      <Input
                        onFocus={() =>
                          !this.state.messageFocused &&
                          this.setState({
                            messageFocused: true,
                          })
                        }
                        onBlur={() =>
                          this.state.messageFocused &&
                          this.setState({ messageFocused: false })
                        }
                        onChange={e =>
                          this.setState({ message: e.target.value })
                        }
                        innerRef={this.messageInput}
                        className="message-textarea"
                        type="textarea"
                        name="message"
                        id="message"
                        placeholder={message.messagePlaceholder}
                      />
                    </FormGroup>
                    <FormGroup
                      id="email-wrapper"
                      className={`form-group-default ${this.state
                        .emailFocused && 'focused'}`}
                    >
                      <Label for="email">
                        {message.emailLabel}
                        <span className="email-required-asterisk">
                          <strong>*</strong>
                        </span>
                      </Label>
                      <Input
                        required
                        onFocus={() =>
                          !this.state.emailFocused &&
                          this.setState({
                            emailFocused: true,
                          })
                        }
                        onBlur={() =>
                          this.state.emailFocused &&
                          this.setState({ emailFocused: false })
                        }
                        onChange={e => this.setState({ email: e.target.value })}
                        innerRef={this.emailInput}
                        className="message-textarea"
                        placeholder={message.emailPlaceholder}
                        type="email"
                        name="email"
                        id="email"
                      />
                      <FormText color="muted">{message.emailInfo}</FormText>
                    </FormGroup>
                    <div className="message-button-wrapper">
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="message-button"
                        >
                          <FormattedMessage id={this.state.buttonState} />
                          {this.state.buttonState === buttonState.SENDING && (
                            <FontAwesomeIcon
                              className="mx-2"
                              icon={faSpinnerThird}
                              spin
                            />
                          )}
                        </Button>
                        <p className="message-recaptcha-disclaimer">
                          This website is protected by reCAPTCHA.
                          <br />
                          Google{' '}
                          <a
                            href="https://policies.google.com/privacy"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://policies.google.com/terms"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Terms of Service
                          </a>{' '}
                          apply.
                        </p>
                      </div>
                    </div>
                  </Form>
                  <p className="text-center">
                    {message.urgent}{' '}
                    <a href={`tel:${message.tel}`}>{message.tel}</a>, (USA) {''}
                    <a href={`tel:${message.telUS}`}>{message.telUS}</a> or
                    <br />
                    (UK) <a href={`tel:${message.telUK}`}>
                      {message.telUK}
                    </a>{' '}
                    {message.chat}
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      />
    )
  }
}

export default withGoogleReCaptcha(Message)
