import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

interface IProps {
  questionId: string
  answerId: string
}

const Question: React.FC<IProps> = ({ questionId, answerId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className="question-container" onClick={toggle}>
      <p className="question-question">
        <FormattedMessage id={questionId} />
      </p>
      <Collapse isOpen={isOpen}>
        <p className="question-answer">
          <FormattedMessage id={answerId} />
        </p>
      </Collapse>
    </div>
  )
}

export default Question
